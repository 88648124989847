import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { HiOutlineLockClosed } from "react-icons/hi"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      {children}
      <div className="h-[1px] bg-dark-400"></div>

      <section className="bg-dark-500 grid grid-cols-1" id="contact">
        <a
          href="mailto:hey@motionwares.com?subject=Start A Project"
          className="relative px-6 flex flex-col min-h-screen text-white before:transition-all before:ease-in-out before:h-screen before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-dark-900 before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[0]"
        >
          <h1 className="font-serif font-bold md:leading-snug text-center text-4xl md:text-5xl my-auto flex flex-col z-[1]">
            <span>Got a project?</span>
            <span>
              Let’s talk<span className="text-bold-500">.</span>
            </span>
          </h1>
        </a>
      </section>

      <footer className="bg-white relative" data-scroll-section>
        <div className="container min-h-screen grid grid-cols-1 md:grid-cols-4">
          <div className="pb-16 pt-32 md:pt-52 mt-auto">
            <h4 className="font-serif font-semibold text-2xl">
              motionwares<span className="text-bold-500">.</span>
            </h4>
          </div>
          <div className="pt-16 md:py-16 md:pt-52">
            <p className="mb-2">Company</p>
            <ul className="space-y-2 tracking-tight footer__links mb-16">
              <li>
                <div
                  // to="/"
                  className="flex space-x-1 text-lg md:text-xl font-semibold text-gray-300 hover:text-"
                >
                  <span>About Us</span>
                  <HiOutlineLockClosed size="1.15em" className="my-auto" />
                </div>
              </li>
              <li>
                <div
                  // to="/"
                  className="flex space-x-1 text-lg md:text-xl font-semibold text-gray-300 hover:text-"
                >
                  <span>Our Portfolio</span>
                  <HiOutlineLockClosed size="1.15em" className="my-auto" />
                </div>
              </li>
              <li>
                <div
                  // to="/"
                  className="flex space-x-1 text-lg md:text-xl font-semibold text-gray-300 hover:text-"
                >
                  <span>Publications</span>
                  <HiOutlineLockClosed size="1.15em" className="my-auto" />
                </div>
              </li>
              <li>
                <div
                  // to="/"
                  className="flex space-x-1 text-lg md:text-xl font-semibold text-gray-300 hover:text-"
                >
                  <span>Podcasts</span>
                  <HiOutlineLockClosed size="1.15em" className="my-auto" />
                </div>
              </li>
              <li>
                <div
                  // to="/"
                  className="flex space-x-1 text-lg md:text-xl font-semibold text-gray-300 hover:text-"
                >
                  <span>Store</span>
                  <HiOutlineLockClosed size="1.15em" className="my-auto" />
                </div>
              </li>
            </ul>

            <p className="mb-2">Get in Touch</p>
            <ul className="space-y-2 tracking-tight footer__links">
              <li>
                <a
                  href="mailto:hey@motionwares.com"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  hey@motionwares.com
                </a>
              </li>
            </ul>
          </div>
          <div className="pt-16 md:py-16 md:pt-52">
            <p className="mb-2">Our Social Media</p>
            <ul className="space-y-2 tracking-tight footer__links">
              <li>
                <Link
                  target="_blank"
                  to="//twitter.com/motionwares"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  Twitter
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="//facebook.com/motionwares"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  Facebook
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="//linkedin.com/company/motionwares"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  LinkedIn
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="//instagram.com/motionware"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  Instagram
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="//behance.com/motionwares"
                  className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                >
                  Behance
                </Link>
              </li>
            </ul>
          </div>
          <div className="py-16 md:pt-52">
            {/* <p className="mb-6">Other Stuffs</p> */}
            <ul className="space-y-4 tracking-tight footer__links">
              <li>
                <Link
                  to="//pro.motionwares.com"
                  className="text-xl md:text-2xl font-medium text-gray-500 hover:text-black"
                >
                  Join Our Team
                </Link>
              </li>
              <li>
                <Link
                  to="#contact"
                  className="text-xl md:text-2xl font-medium text-gray-500 hover:text-black"
                >
                  Start a Project
                </Link>
              </li>
              <li>
                <Link
                  to="//hire.motionwares.com"
                  className="text-xl md:text-2xl font-medium text-gray-500 hover:text-black"
                >
                  Hire a Professional
                </Link>
              </li>
            </ul>
          </div>
          <div className="md:col-span-3 mt-auto pb-4">
            ©{new Date().getFullYear()} All Rights Reserved.
          </div>
          <div className="md:col-span-3 mt-auto pb-24">
            <h6 className="font-serif font-semibold text-sm text-gray-400">
              Disclaimer
            </h6>
            <p className="prose prose-sm text-gray-400">
              Motionwares is a trademark of Motionwares Digital Solutions Ltd,
              duly registered with the Corporate Affairs Commission, CAC.
              <br />
              All logos used are trademarked to their respective organisations.
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
