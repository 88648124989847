import { Dialog, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import { HiOutlineLockClosed } from "react-icons/hi"
const mainNav = [
  { name: "Start a Project", href: "#contact" },
  { name: "Hire a Professional", href: "//hire.motionwares.com" },
]

const navigation = [
  { name: "Our Academy", href: "//learn.motionwares.com" },
  { name: "Hire a Professional", href: "//hire.motionwares.com" },
  { name: "Get Hired", href: "//pro.motionwares.com" },
  { name: "Our Blog", href: "//blog.motionwares.com" },
]

const Header = ({ siteTitle }) => {
  let [open, setOpen] = useState<boolean>(false)
  const toggle = React.useCallback(() => {
    setOpen(v => !v)
  }, [])
  return (
    <>
      <Link to="/" className="m-6 md:m-10 mix-blend-difference z-[1000] fixed">
        <svg fill="none" viewBox="0 0 60 60" className="h-[60px] w-[60px]">
          <text className="sr-only">Motionwares Logo</text>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60 0H0v60h60V0zM33.917 34.85l-4.315-4.244h-5.206l6.953 6.942 2.568-2.698zm6.955 0l-4.316-4.244H31.35l6.955 6.942 2.568-2.698zm4.398-4.303l-3.495 3.47-3.471-3.47h6.966zm-30.539-1.165l3.495-3.46 3.471 3.46h-6.966zm11.352-4.232l4.328 4.232h5.195l-6.943-6.93-2.58 2.698zm-6.954 0l4.315 4.232h5.207l-6.942-6.93-2.58 2.698z"
            fill="#ffffff"
          />
        </svg>
      </Link>
      <nav className="right-0 fixed mix-blend-difference tracking-normal z-[1000] p-6 md:p-10 h-[60px] box-content flex items-center justify-between font-serif space-x-8 font-semibold">
        {!open && (
          <>
            {mainNav.map(item => (
              <Link
                key={item.name}
                to={item.href}
                className="hidden md:inline-flex transition-colors ease-in-out text-white"
              >
                {item.name}
              </Link>
            ))}
          </>
        )}
        {open && (
          <button
            onClick={() => setOpen(false)}
            className="font-semibold relative text-white ring-1 ring-dark-400 ring-opacity-5 hover:text-black border-2 border-white px-6 py-2 before:transition-all before:ease-in-out before:h-full before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-white before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[-1]"
          >
            Close
          </button>
        )}
        {!open && (
          <button
            onClick={() => setOpen(true)}
            className="font-semibold relative text-white ring-1 ring-dark-400 ring-opacity-5 hover:text-black border-2 border-white px-6 py-2 before:transition-all before:ease-in-out before:h-full before:w-full before:transform before:top-0 before:left-0 before:absolute before:bg-white before:scale-y-0 before:origin-bottom hover:before:scale-y-100 before:z-[-1]"
          >
            Menu
          </button>
        )}
      </nav>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999] overflow-hidden"
          onClose={() => setOpen(false)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-white bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 w-screen flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="relative w-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 bg-transparent h-[114px] z-[998] w-full">
                      <button></button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col pt-[112px] md:pt-[148px] bg-white overflow-y-scroll">
                    <div className="relative flex-1 px-6 md:px-10">
                      {/* Replace with your content */}
                      <div className="grid grid-cols-1 md:grid-cols-3 text-black min-h-[76vh]">
                        <div className="mt-auto md:pr-8"></div>
                        <div className="">
                          <p className="mb-2">Company</p>
                          <ul className="space-y-4 md:space-y-4 text-lg md:text-2xl font-semibold tracking-tight footer__links mb-6 md:mb-0">
                            <li>
                              <div
                                // to="/"
                                className="flex space-x-1 text-gray-300 hover:text-"
                              >
                                <span>About Us</span>
                                <HiOutlineLockClosed
                                  size="1.15em"
                                  className="my-auto"
                                />
                              </div>
                            </li>
                            <li>
                              <div
                                // to="/"
                                className="flex space-x-1 text-gray-300 hover:text-"
                              >
                                <span>Our Portfolio</span>
                                <HiOutlineLockClosed
                                  size="1.15em"
                                  className="my-auto"
                                />
                              </div>
                            </li>
                            <li>
                              <div
                                // to="/"
                                className="flex space-x-1 text-gray-300 hover:text-"
                              >
                                <span>Publications</span>
                                <HiOutlineLockClosed
                                  size="1.15em"
                                  className="my-auto"
                                />
                              </div>
                            </li>
                            <li>
                              <div
                                // to="/"
                                className="flex space-x-1 text-gray-300 hover:text-"
                              >
                                <span>Podcasts</span>
                                <HiOutlineLockClosed
                                  size="1.15em"
                                  className="my-auto"
                                />
                              </div>
                            </li>
                            <li>
                              <div
                                // to="/"
                                className="flex space-x-1 text-gray-300 hover:text-"
                              >
                                <span>Store</span>
                                <HiOutlineLockClosed
                                  size="1.15em"
                                  className="my-auto"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="">
                          <ul className="text-xl md:text-4xl font-bold space-y-4 md:space-y-8 tracking-tight footer__links mb-16 md:mb-0">
                            <li>
                              <Link
                                to="//pro.motionwares.com"
                                className="text-gray-500 hover:text-black"
                              >
                                Join Our Team
                              </Link>
                            </li>
                            <li>
                              <a
                                href="mailto:hey@motionwares.com?subject=Start A Project"
                                className="text-gray-500 hover:text-black"
                              >
                                Start a Project
                              </a>
                            </li>
                            <li>
                              <Link
                                to="//hire.motionwares.com"
                                className="text-gray-500 hover:text-black"
                              >
                                Hire a Professional
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="py-6 md:py-0 mt-auto">
                          <h4 className="font-serif font-semibold text-xl md:text-2xl md:text-">
                            motionwares<span className="text-bold-500">.</span>
                          </h4>
                        </div>
                        <div className="mt-auto pb-6 md:pb-0">
                          <p className="text-dark-200 mb-2">
                            We're a Product Design & Development Agency.
                          </p>
                          <ul className="flex flex-row space-x-2 tracking-tight footer__links">
                            <li className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black">
                              Follow us —
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="//twitter.com/motionwares"
                                className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                              >
                                Tw
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="//facebook.com/motionwares"
                                className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                              >
                                Fb
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="//linkedin.com/company/motionwares"
                                className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                              >
                                Li
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="//instagram.com/motionware"
                                className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                              >
                                In
                              </Link>
                            </li>
                            <li>
                              <Link
                                target="_blank"
                                to="//behance.com/motionwares"
                                className="text-lg md:text-xl font-semibold text-gray-500 hover:text-black"
                              >
                                Be
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Header
