import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

interface SEOProps {
  description?: undefined | string
  lang?: undefined | string
  meta?: any
  title: undefined | string
}
function SEO({ description, lang = "en", meta = [], title }: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
            twitterImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s ‣ ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: site.siteMetadata?.image,
        },
        {
          property: "og:image:width",
          content: 1200,
        },
        {
          property: "og:image:height",
          content: 630,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: site.siteMetadata?.twitterImage,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ].concat(meta)}
    >
      <meta name="description" content={metaDescription} />
      <meta name="image" content={site.siteMetadata?.image} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={site.siteMetadata?.url} />
      <meta property="og:image" content={site.siteMetadata?.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={site.siteMetadata?.twitterImage} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name: "Motionwares",
          url: "https://motionwares.com",
          potentialAction: {
            "@type": "SearchAction",
            target: "{search_term_string}",
            "query-input": "required name=search_term_string",
          },
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Corporation",
          name: "Motionwares",
          alternateName: "Motionwares Digital Solutions",
          url: "https://motionwares.com",
          logo: "http://motionwares.com/favicon.jpg",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+2349012709052",
            contactType: "customer service",
            areaServed: ["US", "GB", "CA", "NG", "ZA"],
            availableLanguage: "en",
          },
          sameAs: [
            "https://facebook.com/motionwares",
            "https://twitter.com/motionwares",
            "https://instagram.com/motionware",
            "https://linkedin.com/company/motionwares",
            "https://motionwares.com",
          ],
        })}
      </script>
    </Helmet>
  )
}

export default SEO
